<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9">
      <v-card v-if="billingData" id="invoice">
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <img :src="appLogo" alt="logo" class="me-3">
              </div>
            </div>
            <!-- Right Content -->
            <div>
              <span class="d-block">SIA LeadProm Media</span>
              <span class="d-block">Matisa street, 61 - 31, Riga, Latvia, LV-1009</span>
              <span class="d-block">Reg. number 40203230340</span>
              <span class="d-block">VAT LV40203230340</span>
              <span class="d-block">Email: billing@leadprom.com</span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div class="mb-8 mb-sm-0">
              <p class="font-weight-semibold payment-details-header">Invoice To:</p>
              <p class="merchant-company-name mb-1">
                {{ billingData.company.name }}
              </p>
              <p class="merchant-billing-address mb-1">
                {{ billingData.company.street }}, {{ billingData.company.city }},
                {{ billingData.company.zip }}, {{ billingData.company.country }}
              </p>
              <p class="merchant-tax-id mb-1">
                {{ billingData.company.taxId }}
              </p>
            </div>
            <div>
              <p class="invoice-number font-weight-medium text-xl text--primary mb-4">
                Invoice #{{ billingData.company.id }}/{{ billingData.id.split('-')[0] }}
              </p>
              <p class="invoice-creation-date mb-2">
                <span>Date: </span>
                <span class="font-weight-semibold">{{ billingData.createdAt | moment('YYYY-MM-DD') }}</span>
              </p>
              <p class="invoice-due-date mb-2">
                <span>Due date: </span>
                <span class="font-weight-semibold">{{ billingData.createdAt | moment('add', '7 days', 'YYYY-MM-DD') }}</span>
              </p>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template #default>
            <thead>
              <tr>
                <th>Service</th>
                <th>Quantity</th>
                <th>Cost, per item</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="billingData.masterCardEventsCount" class='mastercard-alert'>
                <td class="text-no-wrap">Mastercard Alert</td>
                <td>
                  {{ billingData.masterCardEventsCount }}
                </td>
                <td class="text-no-wrap">
                  {{
                    billingData.masterCardEventsCount
                      ? parseFloat(billingData.masterCardAmount / billingData.masterCardEventsCount).toFixed(
                          2,
                        )
                      : '0.00'
                  }}
                  EUR
                </td>
                <td>{{ billingData.masterCardAmount }} EUR</td>
              </tr>
              <tr v-if="billingData.visaRdrEventsCount" class='visa-rdr'>
                <td class="text-no-wrap">Visa RDR</td>
                <td>
                  {{ billingData.visaRdrEventsCount }}
                </td>
                <td class="text-no-wrap">
                  {{
                    billingData.visaRdrEventsCount
                      ? parseFloat(billingData.visaRdrAmount / billingData.visaRdrEventsCount).toFixed(2)
                      : '0.00'
                  }}
                  EUR
                </td>
                <td>{{ billingData.visaRdrAmount }} EUR</td>
              </tr>
              <tr v-if="billingData.visaCdrnEventsCount" class='visa-cdrn'>
                <td class="text-no-wrap">Visa CDRN</td>
                <td>
                  {{ billingData.visaCdrnEventsCount }}
                </td>
                <td class="text-no-wrap">
                  {{
                    billingData.visaCdrnEventsCount
                      ? parseFloat(billingData.visaCdrnAmount / billingData.visaCdrnEventsCount).toFixed(2)
                      : '0.00'
                  }}
                  EUR
                </td>
                <td>{{ billingData.visaCdrnAmount }} EUR</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <table v-if="billingData.subTotalAmount" class="w-full">
                <tr>
                  <td class="pe-16">Sub Total:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'sub-total text-right'">{{ billingData.subTotalAmount }} EUR</th>
                </tr>
              </table>
              <table v-if="billingData.monthlyMinimum" class="w-full">
                <tr>
                  <td class="pe-16">Monthly minimum:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'monthly-minimum text-right'">{{ billingData.monthlyMinimum }} EUR</th>
                </tr>
              </table>
              <table v-if="billingData.vat" class="w-full">
                <tr>
                  <td class="pe-16">VAT: {{ billingData.vat }} %</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'vat text-right'">{{ parseFloat(billingData.vatAmount).toFixed(2) }} EUR </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr v-for="(customCorrection, index) in billingData.billingCustomCorrections" :key="index">
                  <td style="max-width: 200px" class="pe-16">{{customCorrection.name}}:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-correction-amount text-right'">{{ customCorrection.value }} EUR</th>
                </tr>
                <tr v-if="billingData.totalAmountConverted">
                  <td class="pe-16">Total in EUR:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-total-in-eur text-right'">{{ billingData.totalAmount }} EUR</th>
                </tr>
                <tr v-else>
                  <td class="pe-16">Total:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-total-in-eur text-right'">{{ billingData.totalAmount }} EUR</th>
                </tr>
                <tr v-if="billingData.exchangeRate">
                  <td class="pe-16">Exchange rate:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'exchange-rate text-right'">
                    {{ billingData.exchangeRate }}
                  </th>
                </tr>
                <tr v-if="billingData.totalAmountConverted">
                  <td class="pe-16">Total in {{ billingData.currency }}:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-total-in-usd text-right'">
                    {{ billingData.totalAmountConverted }} {{ billingData.currency }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-card-title> Payment instructions </v-card-title>
        <v-card-text id='bank-billing-credentials' v-if="billingData.billingCredentials">
          {{ billingData.billingCredentials.name }}
          <table>
            <tr v-for="(info, index) in billingData.billingCredentials.items" :key="index">
              <td class="pe-16">{{ info.label }}:</td>
              <th class="text-left">{{ info.value }}</th>
            </tr>
          </table>
        </v-card-text>
        <v-card-text id='non-banking-billing-credentials' v-if="billingData.onlinePaymentBillingCredentials">
          {{ billingData.onlinePaymentBillingCredentials.title }}
          <table>
            <tr>
              <td class="pe-16">Account name:</td>
              <th class="text-left">{{ billingData.onlinePaymentBillingCredentials.accountName }}</th>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="action-buttons" cols="12" md="3">
      <v-card v-if="billingData">
        <v-card-text>
          <v-btn v-if="isAdmin" color="primary" class="billing-list-send-invoice-button mb-3" block @click="sendInvoice">
            <v-icon class="me-2" left>
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Invoice</span>
          </v-btn>
          <v-btn class="billing-list-download-invoice-button mb-3" color="secondary" block outlined @click="downloadInvoice"> Download </v-btn>
          <v-edit-dialog v-if="isAdmin" large persistent @save="saveCustomCorrection()">
            <v-btn class="billing-list-add-custom-correction-button mb-3 w-full" color="secondary" block outlined> Add Custom Correction </v-btn>
            <template #input>
              <div class="mt-4 title">Add Custom Correction</div>
              <v-text-field class="name"
                v-model="customCorrection.name"
                :rules="[validators.required]"
                type="text"
                label="Name"
              ></v-text-field>

              <v-text-field class="amount"
                v-model="customCorrection.value"
                :rules="[validators.required]"
                type="number"
                label="Amount"
              ></v-text-field>
            </template>
          </v-edit-dialog>

          <v-btn v-if="billingData.company.isBillingDetailInvoice" class="billing-list-download-receipt-button mb-3" color="secondary" block outlined @click="downloadReceipt"> Download Receipt </v-btn>
          <v-btn class="billing-list-print-invoice-button mb-3" color="secondary" block outlined @click="printInvoice"> Print </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="history" cols="12" md="9" v-if="isAdmin && historyData && historyData.length">
      <v-card>
        <v-card-title> History </v-card-title>
        <v-card-text id='billing-history'>
          <table>
            <tr v-for="(history, historyIndex) in historyData" :key="historyIndex">
              <td class="pe-16">{{ history.createdAt  | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              <td >{{ history.message }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js';
import themeConfig from '@themeConfig';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';
import Vue from 'vue';
import { required } from '@core/utils/validation';

export default {
  setup() {
    const isAdmin = getProfile().role === Roles.ADMIN;
    const billingData = ref(null);
    const historyData = ref(null);
    const customCorrection = ref({
      name: null,
      value: null,
    });

    const fetchBilling = () => {
      store
        .dispatch('billing/fetchBillingById', { id: router.currentRoute.params.id })
        .then(async response => {
          const invoiceData = response.data;

          if (!invoiceData.billingCredentials) {
            try {
              const billingCredentials = await store.dispatch('billingCredentials/fetchDefaultBillingCredentials', invoiceData.currency);

              invoiceData.billingCredentials = billingCredentials.data;
            } catch (error) {
              console.error('Can not load billing credentials'. error);
            }
          }

          if (invoiceData.vat) {
            let amount = Number(invoiceData.masterCardAmount) + Number(invoiceData.visaRdrAmount) + Number(invoiceData.visaCdrnAmount);

            if (invoiceData.monthlyMinimum && Number(invoiceData.monthlyMinimum) > amount) {
              amount = Number(invoiceData.monthlyMinimum);
            }

            invoiceData.vatAmount = amount * (invoiceData.vat / 100);
          }

          return invoiceData;
        })
        .then(invoiceData => billingData.value = invoiceData)
        .catch(error => {
          if (error.response && error.response.status === 404) {
            billingData.value = undefined;
          }
        });
    };

    const fetchHistory = () => {
      store
        .dispatch('billing/getHistory', { id: router.currentRoute.params.id })
        .then(response => {
          historyData.value = response.data;
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            historyData.value = undefined;
          }
        });
    };

    fetchBilling();
    fetchHistory();

    const saveCustomCorrection = () => {
      if (!customCorrection.value.name && !customCorrection.value.amount) {
        Vue.notify({
          type: 'error',
          title: 'Billing',
          text: 'Cannot save custom correction. Please provide all data!',
        });
        return;
      }

      store
        .dispatch('billing/saveCustomCorrection', {
          id: router.currentRoute.params.id,
          customCorrection: customCorrection.value
        })
        .then(() => {
          fetchBilling();
          fetchHistory();
          customCorrection.value = {
            name: null,
            value: null,
          };
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Billing',
            text: 'Cannot save custom correction',
          });
        });
    };

    const printInvoice = () => {
      window.print();
    };

    const sendInvoice = () => {
      store
        .dispatch('billing/sendInvoices', { ids: [router.currentRoute.params.id] })
        .then(() => {
          Vue.notify({
            type: 'success',
            title: 'Invoice',
            text: 'Invoice has been sent successfully',
          });
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Invoice',
            text: 'Cannot send invoice',
          });
        });
    };

    const downloadInvoice = () => {
      store.dispatch('billing/downloadInvoice', { id: router.currentRoute.params.id }).then(response => {
        const fileURL = URL.createObjectURL(response);
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Invoice #${billingData.value.company.id} ${billingData.value.period}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
        URL.revokeObjectURL(fileURL);
      });
    };

    const downloadReceipt = () => {
      store.dispatch('billing/downloadReceipt', { id: router.currentRoute.params.id }).then(response => {
        const fileURL = URL.createObjectURL(response);
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Receipt #${billingData.value.company.id} ${billingData.value.period}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
        URL.revokeObjectURL(fileURL);
      });
    };

    return {
      isAdmin,
      billingData,
      historyData,
      printInvoice,
      downloadInvoice,
      downloadReceipt,
      sendInvoice,
      customCorrection,
      saveCustomCorrection,
      validators: {
        required,
      },
      appLogo: themeConfig.app.logo,
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    };
  },
};
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.v-small-dialog__activator__content {
  width: 100% !important;
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .history {
    display: none !important;
  }

  .action-buttons {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0 !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
